.social-bar-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  justify-content: center;
}
.social-bar-link {
  display: contents;
}
.social-bar-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.social-bar-link1 {
  display: contents;
}
.social-bar-icon2 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.social-bar-root-class-name {
  left: 0px;
  bottom: 0px;
  position: absolute;
}
.social-bar-root-class-name2 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: static;
}
.social-bar-root-class-name3 {
  left: 0px;
  bottom: 0px;
  position: absolute;
}
.social-bar-root-class-name4 {
  left: -4px;
  bottom: 260px;
  position: static;
}
@media(max-width: 767px) {
  .social-bar-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .social-bar-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .social-bar-root-class-name4 {
    left: 0px;
    bottom: 0px;
    position: absolute;
  }
}
