.about-me-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.about-me-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.about-me-image {
  width: 200px;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
}
.about-me-separator {
  color: var(--dl-color-gray-black);
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
  background-color: #000000;
}
.about-me-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.about-me-resume {
  text-decoration: none;
}
.about-me-accordion-content {
  display: flex;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s ease-in-out;
  align-items: center;
  flex-direction: column;
}
.about-me-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-me-banner {
  width: 100%;
  height: 515px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-me-text {
  font-size: 1.5rem;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Courier New;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-me-footer {
  width: 176px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.about-me-link {
  display: contents;
}
.about-me-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-me-link1 {
  display: contents;
}
.about-me-icon2 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
@media(max-width: 1600px) {
  .about-me-separator {
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .about-me-nav {
    padding-top: 16px;
  }
  .about-me-text {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .about-me-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-me-image {
    align-self: center;
  }
  .about-me-separator {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-me-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-me-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .about-me-header {
    top: 0px;
    left: 0px;
    padding: var(--dl-space-space-unit);
    position: absolute;
  }
  .about-me-separator {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-me-banner {
    height: auto;
    align-self: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-me-text {
    align-self: center;
    margin-bottom: 0px;
  }
  .about-me-footer {
    width: 100%;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .about-me-icon {
    align-self: center;
    margin-right: unit;
  }
  .about-me-icon2 {
    margin-left: unit;
  }
}
